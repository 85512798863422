<template>
  <div>
    <div class="spinner" />
  </div>
</template>

<style scoped lang="scss">
.spinner {
  @apply w-[100px] h-[100px] relative after:animate-[down_2.4s_cubic-bezier(0,0,0.24,1.21)_infinite]
      after:left-[30px] after:top-[30px];
}
.spinner::after,
.spinner::before {
  @apply box-border absolute content-[""] w-[30px] h-[30px] bg-blue-1
    animate-[up_2.4s_cubic-bezier(0,0,0.24,1.21)_infinite] left-1/2 top-1/2;
}
.spinner::after {
  @apply bg-yellow-1
    animate-[down_2.4s_cubic-bezier(0,0,0.24,1.21)_infinite] left-[calc(50%-30px)] top-[calc(50%-30px)];
}

@keyframes down {
  0%, 100% {
    transform: none;
  }

  25% {
    transform: translateX(100%);
  }

  50% {
    transform: translateX(100%) translateY(100%);
  }

  75% {
    transform: translateY(100%);
  }
}

@keyframes up {
  0%, 100% {
    transform: none;
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-100%) translateY(-100%);
  }

  75% {
    transform: translateY(-100%);
  }
}

</style>
