/* eslint-disable import/order */
import {
  RouteRecordRaw,
  createRouter,
  createWebHistory,
} from 'vue-router';
import {
  CONFIG,
  CONSTANTS,
  IS_DEVELOPMENT,
  hasRouteAccess,
  redirectUserAfterLogin,
} from '@/helpers';
import {
  useStore,
} from '@/store';
import Bus, {
  NOTIFICATION,
} from '@/bus';

// Auth
const AuthView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/AuthView.vue'
);
const LoginView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/LoginView.vue'
);

// Admin
const AdminTemplate = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "admin" */
  '@/views/Admin/AdminTemplate.vue'
);
const UserList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "admin-user-list" */
  '@/views/Admin/UserList.vue'
);
const MutateUser = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "admin-mutate-user" */
  '@/views/Admin/MutateUser.vue'
);
// Individual
const UserView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "individual/user-view" */
  '@/views/Individual/UserView.vue'
);
const AccountView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "individual/user-account-view" */
  '@/views/Individual/AccountView.vue'
);

const isGuest = {
  meta: {
    isGuest: true,
  },
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth',
    alias: '/',
    name: 'Auth',
    component: AuthView,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: LoginView,
        meta: {
          title: 'Login',
          ...isGuest.meta,
        },
      },
    ],
  },
  {
    path: '/user',
    name: 'User',
    component: UserView,
    children: [
      {
        path: 'account/view',
        name: 'AccountView',
        component: AccountView,
        meta: {
          title: 'Account View',
        },
      },
    ],
  },
  {
    path: '/admin',
    name: 'AdminTemplate',
    component: AdminTemplate,
    children: [
      {
        path: 'user-list',
        name: 'AdminList',
        component: UserList,
        meta: {
          title: 'Admin List',
        },
      },
      {
        path: 'user/add/:id',
        alias: 'user/edit/:id',
        name: 'MutateUser',
        component: MutateUser,
        meta: {
          title: 'Add/Edit Admin',
        },
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "admin-settings" */ '../views/Admin/MutateSettings.vue'),
        meta: {
          title: 'Settings',
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  useStore.app.prevRoutePath = from.fullPath;
  useStore.app.isLoading = true;

  // ensuring user load from local storage attempted before routing
  await useStore.app.init();
  next();
});

router.afterEach((to, from, failure) => {
  useStore.app.isLoading = false;
});

router.beforeResolve((to, from, next) => {
  const { user } = useStore.auth;

  const titleSuffix = user && IS_DEVELOPMENT
    ? ` | ${user.username}`
    : '';
  if (!to.meta || !to.meta.title) {
    document.title = `${CONFIG.appName}${titleSuffix}`;
  } else {
    document.title = `${to.meta.title}${titleSuffix}`;
  }

  // Redirect unauthenticated users to the login page
  if (!user) {
    if (to.fullPath !== CONSTANTS.ROUTES.GUEST_HOME) {
      next(CONSTANTS.ROUTES.GUEST_HOME);
      return;
    }
    next();
    return;
  }

  const hasAccess = hasRouteAccess(to.path, user.role);
  if (!hasAccess) {
    Bus.emit(NOTIFICATION.INFO, {
      message: `Sorry, you can't access the page ${to.path}`,
    });
  }

  if (to.path === '/' || !hasAccess) {
    next(redirectUserAfterLogin(user).route);
    return;
  }

  // Allow authenticated users to proceed
  next();
});

export { routes };

export default router;
