import axios, {
  AxiosError,
  AxiosProgressEvent,
  AxiosRequestConfig,
} from 'axios';
import {
  getAppInfo,
} from 'utilities';
import CONSTANTS from './constants';
import Bus, {
  NOTIFICATION,
} from '@/bus';
import {
  baseServerUrl,
} from '@/configs/client.config.json';
import {
  IDevice,
  IUser,
  IUserRole,
} from '@/type';

export { default as CONSTANTS } from './constants';

export const {
  SERVER_PORT,
  CLIENT_PORT,
  IS_DEVELOPMENT,
  CONFIG,
  ROUTE_API,
} = getAppInfo();

const { protocol, hostname } = window.location;

// for local testing, baseServerUrl = https://localhost:3000 can be set, if necessary
export const baseUrl = baseServerUrl || (IS_DEVELOPMENT
  ? `${protocol}//${hostname}:${SERVER_PORT}`
  : window.location.origin);

export const clientBaseUrl = IS_DEVELOPMENT
  ? `${protocol}//${hostname}:${CLIENT_PORT}`
  : window.location.origin;

export function abbreviateName(firstName?: string, lastName?: string): string {
  if (firstName && lastName) {
    return (firstName[0] + lastName[0]).toUpperCase();
  }
  return (firstName || lastName)?.toUpperCase() || 'N/A';
}

export function viewportBreakpoint(): string {
  const width = window.innerWidth;
  let breakpoint = 'xs';

  if (width >= 640) {
    breakpoint = 'sm';
  }
  if (width >= 768) {
    breakpoint = 'md';
  }
  if (width >= 1024) {
    breakpoint = 'lg';
  }
  if (width >= 1280) {
    breakpoint = 'xl';
  }
  if (width >= 1536) {
    breakpoint = '2xl';
  }
  return breakpoint;
}

export function getFileSrc(
  fileName: string,
  local = false,
  url = false,
): string {
  if (!fileName.length || fileName.startsWith('http')) {
    return fileName;
  }

  let fullPath: string;
  if (fileName.includes('/')) {
    // Split and encode only if there's a directory path
    const pathParts = fileName.split('/');
    const encodedFileName = encodeURIComponent(pathParts.pop() || '');
    const directoryPath = pathParts.join('/');
    fullPath = `${directoryPath}/${encodedFileName}`;
  } else {
    // Encode the file name directly if there's no directory
    fullPath = encodeURIComponent(fileName);
  }

  // Construct the URL parameters with the full path
  const params = new URLSearchParams({
    fileName: fullPath,
    ...(local && {
      local: '1',
    }),
    ...(url && {
      url: '1',
    }),
  });

  const fileUrl = `${baseUrl}${ROUTE_API}/files?${params.toString()}`;
  return fileUrl;
}

export function convertBlobUrlToDataUrl(blobUrl: string): Promise<string> {
  return fetch(blobUrl)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      return response.blob();
    })
    .then((blob) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = (): void => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject(new Error('FileReader did not produce a string.'));
        }
      };
      reader.onerror = (error): void => reject(error);
      reader.readAsDataURL(blob);
    }));
}

export function getFileSignedUrl(
  { fileName, method, size, isPublic }: {
    fileName: string;
    method: 'get' | 'put';
    size?: number;
    isPublic?: boolean
  },
): string {
  let fullPath;
  if (fileName.includes('/')) {
    // Split and encode only if there's a directory path
    const pathParts = fileName.split('/');
    const encodedFileName = encodeURIComponent(pathParts.pop() || '');
    const directoryPath = pathParts.join('/');
    fullPath = `${directoryPath}/${encodedFileName}`;
  } else {
    // Encode the file name directly if there's no directory
    fullPath = encodeURIComponent(fileName);
  }

  // Construct the URL parameters
  const params = new URLSearchParams({
    fileName: fullPath,
    method,
    ...(size !== undefined && {
      size: size.toString(),
    }),
    ...(isPublic && {
      isPublic: '1',
    }),
  });

  const url = `/files/file-signed-url?${params.toString()}`;
  return url;
}

export function handleAxiosError<T extends {
  message: string;
  errors?: Map<string, string> | Record<string, string | undefined>;
}>(e: AxiosError<T>, defaultMessage = 'Sorry, an error occurred', showAdditionalError = false): void {
  console.error(e);

  if (!e.isAxiosError || !e?.response?.data) {
    Bus.emit(NOTIFICATION.ERROR, {
      message: defaultMessage,
      permanent: true,
    });
    return;
  }

  let message = '';
  let errorMessages = '';

  if (typeof e.response.data === 'string') {
    message = e.response.data;
  } else if (e.response.data.message) {
    message = e.response.data.message;
  } else {
    message = defaultMessage || e.response.statusText;
  }

  if (e.response.data.errors && showAdditionalError) {
    const { errors } = e.response.data;
    for (const key of Object.keys(errors)) {
      errorMessages += `, ${key}: `;
      if (errors instanceof Map) {
        errorMessages += errors.get(key);
      } else {
        errorMessages += errors[key];
      }
    }
  }

  const finalMessage = message + errorMessages;

  Bus.emit(NOTIFICATION.ERROR, {
    message: finalMessage,
    permanent: true,
  });
}

export function identifyDeviceType(): IDevice {
  let isMobile = false;
  if (
    /Android/i.test(navigator.userAgent)
    && /Mobile/i.test(navigator.userAgent)
  ) {
    isMobile = true;
  } else if (/webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    isMobile = true;
  } else if (window.innerWidth < 768) {
    isMobile = true;
  }

  const isTouchDevice = 'ontouchend' in document;
  const isChrome = /Chrome/i.test(navigator.userAgent) || /CriOS/i.test(navigator.userAgent);
  const isSafari = /Safari/i.test(navigator.userAgent) && !isChrome;
  const isFirefox = /Firefox/i.test(navigator.userAgent);
  const isMacintosh = /Macintosh/i.test(navigator.userAgent);
  const isMac = isMacintosh && !isTouchDevice;
  const isWindows = /Windows/i.test(navigator.userAgent);
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isAndroidTablet = /Android/i.test(navigator.userAgent)
    && !/Mobile/i.test(navigator.userAgent);
  const isLinux = /Linux/i.test(navigator.userAgent) && !isAndroid;
  const isIPad = /iPad/i.test(navigator.userAgent) || (isMacintosh && isTouchDevice);
  const isIPhone = /iPhone/i.test(navigator.userAgent);

  return {
    isMobile,
    isTouchDevice,
    isChrome,
    isSafari,
    isFirefox,
    isMac,
    isWindows,
    isAndroid,
    isLinux,
    isIPad,
    isIPhone,
    isAndroidTablet,
  };
}

export function redirectUserAfterLogin(
  user: IUser,
): { route: string } {
  const { role } = user;
  if (role) {
    return {
      route: CONSTANTS.ROUTES.ADMIN_HOME,
    };
  }

  // otherwise go to guest url
  return {
    route: CONSTANTS.ROUTES.GUEST_HOME,
  };
}

const ROUTE_ACCESS = {
  '/admin/settings': ['root'],
  '/admin/user-list': ['root'],
};

export function hasRouteAccess(routeName: string, userRole: string): boolean {
  const allowedRoles = ROUTE_ACCESS[routeName];
  // Default to true if no specific roles are defined
  return allowedRoles ? allowedRoles.includes(userRole) : true;
}

export function download(content: string, filename: string): void {
  const element = document.createElement('a');

  if (content.startsWith('http://') || content.startsWith('https://')) {
    element.setAttribute('href', content);
  } else {
    element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`);
  }

  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();
  document.body.removeChild(element);
}

export async function uploadFile(
  file: File,
  filePath: string,
  updateProgress: (progress: number) => void,
) : Promise<string> {
  try {
    const url = getFileSignedUrl({
      fileName: filePath,
      method: 'put',
      size: file.size,
    });

    const signedUrlResp = await axios.get<{ signedUrl: string }>(url);
    const { signedUrl } = signedUrlResp.data;

    const axiosInstance = axios.create();
    delete axiosInstance.defaults.headers.common.Authorization;

    const config: AxiosRequestConfig = {
      onUploadProgress: (e: AxiosProgressEvent) => {
        const progress = (e.loaded / (e.total || 1)) * 100;
        updateProgress(progress);
      },
    };

    await axiosInstance.put(signedUrl, file, config);

    return filePath;
  } catch (error) {
    const errMsg = `Error uploading file ${file.name}`;
    console.error(`${errMsg}:`, error);

    Bus.emit(NOTIFICATION.ERROR, {
      message: errMsg,
      permanent: true,
    });

    throw error;
  }
}

export function toEnNum(n: string): string {
  return n.replace(/[০-৯]/g, (d) => '০১২৩৪৫৬৭৮৯'.indexOf(d).toString());
}

/**
 * Generates a unique alphanumeric subscription token.
 * @param {number} length - Length of the generated token. Default is 6 characters.
 * @returns {string} The generated unique alphanumeric subscription token.
 */
export function generateSubscriptionToken(length: number = 10): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let token = '';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    token += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return token;
}
